import React from "react"
import { Profiles, Videos } from "../../data/assets"
import { Routes } from "../../data/routes"
import PrimaryButton, { SecondaryButton } from "../../components/button"
import Card from "../../components/card"
import CTA from "../../components/CTA"
import Img from "../../components/img"
import Layout from "../../components/layout"
import PageHeader from "../../components/PageHeaderBlueH1"
import Seo from "../../components/seo"

import {
  SparklesIcon,
  CogIcon,
  CalculatorIcon,
  ChatAlt2Icon,
  QuestionMarkCircleIcon,
  LightBulbIcon,
  LightningBoltIcon,
  TrendingUpIcon,
} from "@heroicons/react/outline"

import { Carousel } from "react-responsive-carousel"

import "react-responsive-carousel/lib/styles/carousel.min.css"

const Content = {
  pageName: "Mathematical Teaching Practices - Effective Math Strategies",
  subheader: "Mathamatical Teaching Practices",
  header: `Yup's evidence-based Teaching Framework drives long-term understanding`,
  description: `Aligned with The National Council of Teachers of Mathematics' (NCTM)
    Effective Teaching Practices, our framework supports Yup tutors in driving
    long-term understanding, not just answer-getting.`,
  video: Videos.guidingQuestions,
  headerCTALink: Routes.schools,
  headerCTAText: "Schedule Demo",

  // Quotes section
  quotes: [
    {
      name: "NCTM",
      source: "Principles to Actions",
      text: `Effective teaching of mathematics builds fluency with procedures on
        a foundation of conceptual understanding so that students, over time,
        become skillful in using procedures flexibly as they solve contextual
        and mathematical problems.`,
    },
    {
      name: "NCTM",
      source: "Catalyzing Change",
      text: `A high sense of agency allows and encourages students to continue
        with a rigorous course of study in mathematics... Mathematical agency
        is about participating in mathematics in ways that are meaningful,
        both personally and socially.`,
    },
    {
      name: "Jo Boaler",
      source: "Mathematical Mindsets",
      text: `A lot of scientific evidence suggests that the difference between
        those who succeed and those who don't is not the brains they were born
        with, but their approach to life, the messages they receive about
        their potential, and the opportunities they have to learn.`,
    },
  ],

  // Learning principles section
  learningPrinciplesHeader: "Three pillars of Yup’s Teaching Framework",
  learningPrinciplesSubheader: `Every day we evaluate session transcripts to
    provide feedback and training that aligns with these three pillars.`,
  learningPrinciplesTutorHeader: "Tutor Action",
  learningPrinciplesStudentHeader: "Student Impact",
  learningPrinciples: [
    {
      header: "Empathy",
      tutorText: `Tutors routinely praise students for effort, perseverance, and
        excellence. They pay close attention to all student questions and signs of
        confusion. By connecting with a tutor at their exact moment of struggle,
        students recognize difficult emotions, channel them productively, and
        experience self-efficacy.`,
      studentText: `Once in the session, students learn to effectively communicate
        their needs in a safe, non-judgmental environment. After each problem is
        completed, tutors give students the option to continue practicing and
        deepen their understanding. There are no time limits on sessions so students
        can choose how long they want to stick around for more learning.`,
      image: "icon-empathy.svg",
    },
    {
      header: "Pedagogy",
      tutorText: `Tutors receive training in instructional best practices. They
        use a question-based approach to help students solve problems and develop
        effective math strategies. They assess prior knowledge, provide examples
        with visual aids, and check for understanding throughout each session.
        Rather than just providing the student with a sequence of steps to replicate,
        tutors build procedural fluency from conceptual understanding.`,
      studentText: `Students are encouraged to build off what they have already
        learned and make connections between mathematical ideas. When students struggle,
        tutors provide scaffolded questioning rather than stepping in to do the work.
        This practice allows students to appreciate that confusion and errors are a
        natural part of learning.`,
      image: "icon-teaching-alt.svg",
    },
    {
      header: "Precision",
      tutorText: `Tutors ensure that student answers and explanations are complete, precise,
        and accurate. They use several tools to aid instruction and make connections,
        including a digital whiteboard, equation writers, graphical interfaces, and image
        sharing. As a result, students develop the habit of showing their work and justifying
        their mathematical thinking using academic vocabulary. They routinely justify the
        accuracy and reasonableness of their solution within the context of the problem.`,
      studentText: ``,
      image: "icon-precision.svg",
    },
  ],

  // Teaching rubric CTA
  teachingRubricPrompt: "Click here to check out our teaching framework.",
  teachingRubricLink: "https://yup.com/downloads/Yup_Teaching_Framework.pdf",
  teachingRubricText: "Yup Teaching Framework",

  // Effective teaching section
  effectiveHeader: "What does Effective Teaching of Math Look Like?",
  effectiveText: `In their landmark publication
    <a href="https://www.nctm.org/uploadedFiles/Standards_and_Positions/PtAExecutiveSummary.pdf" target="_blank" class="text-secondary">Principles to Action</a>,
    NCTM identified
    <a href="https://www.nctm.org/Conferences-and-Professional-Development/Principles-to-Actions-Toolkit/Resources/7-EffectiveMathematicsTeachingPractices/" target="_blank" class="text-secondary">eight mathematical teaching practices</a>
    to describe essential, research-based teaching skills for math instructors.`,
  effectivePractices: [
    {
      name: "Set clear goals",
      description: `Effective math instructors establish clear
        learning goals based on students' math progression and
        use these goals to inform instruction.`,
      icon: SparklesIcon,
    },
    {
      name: "Engage students in reasoning and problem-solving",
      description: `Students need opportunities to discuss and solve
        authentic problems using mathematical reasoning. These problems
        should be complex enough to allow for multiple approaches.`,
      icon: CogIcon,
    },
    {
      name: "Introduce mathematical representations",
      description: `Effective instructors engage students in different
        mathematical representations and encourage them to use these
        representations to make connections to different concepts
        and problem-solving strategies.`,
      icon: CalculatorIcon,
    },
    {
      name: "Facilitate discussion",
      description: `Effective math teachers create opportunities for student
        discourse so students can collaboratively build their understanding.`,
      icon: ChatAlt2Icon,
    },
    {
      name: "Ask purposeful questions",
      description: `Successful teachers pose questions that assess student knowledge
        and expand students' understanding of complex mathematical concepts.`,
      icon: QuestionMarkCircleIcon,
    },
    {
      name: "Build procedural fluency rooted in conceptual understanding",
      description: `Once students have a conceptual foundation, teachers must help
        students employ a variety of procedures quickly and correctly.`,
      icon: LightBulbIcon,
    },
    {
      name: "Encourage productive struggle",
      description: `Effective teachers provide individual students, as well as
        their full class, with opportunities for productive struggle alongside
        scaffolded support.`,
      icon: LightningBoltIcon,
    },
    {
      name: "Assess progress and adjust instruction",
      description: `Teachers must collect ongoing evidence of student thinking through
        formative assessments and tailor their instruction accordingly.`,
      icon: TrendingUpIcon,
    },
  ],

  // Comparison section
  comparisonHeader: "Our Mathematical Teaching Practices",
  comaprisonText: `Yup's tutoring applies NCTM's framework of effective mathematics teaching:`,
  comparisonNCTMSubheader: "NCTM's Effective Mathematics Teaching Practice",
  comparisonYupSubheader: "Yup's Application",
  comparison: [
    {
      practice: "Establish mathematics goals to focus learning",
      application: `Tutoring sessions are based on problems students are
        working on in class, which allows students to continue to work
        towards goals set by their teachers while receiving additional support.`,
    },
    {
      practice: "Implement tasks that promote reasoning and problem solving",
      application: `Yup tutors help students select appropriate problem-solving strategies and
        ensure that students have developed conceptual understanding before focusing on procedures.`,
    },
    {
      practice: "Use and connect mathematical representations",
      application: `Yup tutors use mathematical representations and visuals to quickly convey
        ideas and deepen students' conceptual understanding.`,
    },
    {
      practice: "Facilitate meaningful mathematical discourse",
      application: `Yup tutors help students talk through their mathematical thinking
        using relevant vocabulary.`,
    },
    {
      practice: "Pose purposeful questions",
      application: `Through Socratic questioning, tutors check students’ mathematical
        understandings and scaffold difficult problems.`,
    },
    {
      practice: "Build procedural fluency from conceptual understanding",
      application: `Once tutors check for conceptual understanding, they help students
        connect the procedural steps to these concepts.`,
    },
    {
      practice: "Support productive struggle in learning mathematics",
      application: `Tutors facilitate productive struggle by pushing students to complete
        key portions of the work independently. They provide scaffolds to help students
        understand key concepts, but do not step in and do the work for students.`,
    },
    {
      practice: "Elicit and use evidence of student thinking",
      application: `Yup tutors start a session by assessing students' prior knowledge
        and continue to check student understanding throughout the session.`,
    },
  ],

  // Tutoring team section
  tutoringTeamHeader: "Meet our Tutoring Team",
  tutoringTeamText: `Yup tutors are teachers, education professionals, and graduate
    students with extensive math experience.  Only 5% of applicants pass our
    rigorous screening process for teaching, knowledge and professionalism.
    Once part of the Yup family, tutors receive regular feedback from our team.`,
  tutoringTeamAction: "Learn More",
  tutoringTeamLink: Routes.ourTutors,
  tutoringTeam: [
    {
      name: "Kathleen K",
      role: "Yup Math Tutor",
      profile: Profiles.kathleen,
      bio: `I love tutoring for Yup because I can teach students far beyond the
        limits of time and location. When a student gains the confidence and skills
        to succeed in math, it opens up many new opportunities. I feel grateful to
        play a small role in my students' successes.`,
    },
    {
      name: "Madhu R.",
      role: "Yup Tutor Quality Manager",
      profile: Profiles.madhu,
      bio: `Yup brings people who share a passion for making a difference in education
        together, and the team diligently provides nothing but the best to Yup's users.
        Yup's goal of making education more accessible is sure to bring about a change
        in learning—and I'm excited to be a part of it!`,
    },
  ],

  // Academics team section
  academicsTeamHeader: "Meet our Academics Team",
  academicsTeamText: `Get to know a few core members of our Academics Team with
    backgrounds in teaching, tutoring, and education administration. Collectively,
    we have decades of classroom and school leadership experience. This team is
    responsible for creating and maintaining our Teaching Framework as well as
    working with Yup Tutor Quality Managers to ensure students learn in every session!`,
  academicsTeam: [
    {
      name: "Kreg Moccia",
      role: "Director of Academics",
      profile: Profiles.kreg,
      bio: `Kreg graduated from Harvard University with a degree in Economics and
        earned his Masters in Teaching from the Relay Graduate School of Education.
        He taught the full range of high school math subjects before becoming a
        school leader in Brooklyn, NY. There, he coached a team of 10 teachers,
        leading them to some of the highest academic achievement in the city.`,
      linkedIn: "https://www.linkedin.com/in/kregmoccia/",
    },
    {
      name: "Claire Polcrack",
      role: "Academics Advisor",
      profile: Profiles.claire,
      bio: `Claire graduated from Susquehanna University with a degree in
        Mathematics & Secondary Education. After graduating, she joined Teach For
        America where she taught upper-level math for five years. Most recently,
        Claire was Achievement Director for five high schools in Connecticut and
        New York. At Yup, Claire helps set the vision and strategy for Yup's
        teaching methods.`,
      linkedIn: "https://www.linkedin.com/in/claire-polcrack-37340411b/",
    },
    {
      name: "Emily Banks",
      role: "Academics Advisor",
      profile: Profiles.emily,
      bio: `Emily graduated from Carleton College with a degree in mathematics and
        holds a Master of Arts in Secondary Mathematics Education.  Emily has
        served as a mathematics teacher, curriculum specialist, coach, and most
        recently high school principal.  Under her leadership, Emily's school
        became one of the highest performing high schools in the state,
        earning a gold medal distinction.`,
      linkedIn: "https://www.linkedin.com/in/embanks/",
    },
  ],

  // CTA section
  cta: "Want to learn what Yup can do for your school?",
  ctaActions: [
    {
      link: Routes.schools,
      text: "Schedule Demo",
    },
  ],
}

function ProfileCard(props: { children: React.ReactNode }) {
  return (
    <div className="px-4 max-w-3xl mx-auto h-full">
      <div className="bg-white rounded-lg shadow-lg p-4 sm:p-4 ring-1 ring-gray-900 ring-opacity-5 h-full space-y-4">
        {props.children}
      </div>
    </div>
  )
}

function Quotes() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/testimonials#component-fd7b8bd425f42f6504b22e1ecc6b43c9

  return (
    <section className="bg-secondary mb-12">
      <Carousel
        showStatus={false}
        showArrows={false}
        infiniteLoop={true}
        autoPlay={true}
        interval={10000}
        showThumbs={false}
        width="100vw"
      >
        {Content.quotes.map(quote => (
          <div
            key={quote.text}
            className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center pt-8 pb-12"
          >
            <div className="max-w-3xl mx-auto text-center text-lg sm:text-2xl leading-9 font-medium text-white">
              <p>&ldquo;{quote.text}&rdquo;</p>
            </div>
            <footer className="mt-4">
              <div className="flex items-center justify-center">
                <div className="text-center flex items-center">
                  <div className="text-base font-medium text-white">
                    {quote.name}
                  </div>
                  <svg
                    className="mx-1 h-5 w-5 text-primary"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M11 0h3L9 20H6l5-20z" />
                  </svg>

                  <div className="text-base font-medium text-gray-100">
                    {quote.source}
                  </div>
                </div>
              </div>
            </footer>
          </div>
        ))}
      </Carousel>
    </section>
  )
}

function ValuesList() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/feature-sections#component-fea10362c98dcf74d601fab911a1aee3
  return (
    <div>
      <p className="mt-2 text-gray-900 text-center text-4xl lg:5xl font-extrabold tracking-tight">
        {Content.learningPrinciplesHeader}
      </p>
      <p className="mt-3 text-center text-xl text-gray-500">
        {Content.learningPrinciplesSubheader}
      </p>
      {Content.learningPrinciples.map(value => (
        <Card key={value.header}>
          <div className={`flex flex-col items-center md:flex-row`}>
            <div className="w-full mt-12 sm:mt-16 lg:mt-0 md:w-1/3">
              <div className="p-4 sm:px-6 lg:px-0 lg:m-0 max-w-full">
                <Img
                  className="w-full max-w-sm max-h-72 rounded-xl mx-auto"
                  src={value.image}
                  alt={value.header}
                />
              </div>
            </div>
            <div className="w-full md:w-2/3 text-center md:text-left">
              <div className="mt-6">
                <h2 className="text-3xl px-4 mb-4 font-extrabold tracking-tight text-gray-900">
                  {value.header}
                </h2>
                <p className="mb-4 px-4 text-gray-500">{value.tutorText}</p>
                <p className="mb-4 px-4 text-gray-400">{value.studentText}</p>
              </div>
            </div>
          </div>
        </Card>
      ))}
    </div>
  )
}

function ValuesListLearnMore() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/cta-sections#component-25ce2a81d17545994655ccc4062651a4
  return (
    <div className="bg-secondary">
      <div className="max-w-7xl mx-auto text-center py-8 px-4">
        <h2 className="text-3xl font-extrabold tracking-tight text-white">
          {Content.teachingRubricPrompt}
        </h2>
        <div className="mt-4 flex justify-center">
          <PrimaryButton
            text={Content.teachingRubricText}
            href={Content.teachingRubricLink}
          />
        </div>
      </div>
    </div>
  )
}

function EffectiveTeachingSection() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/feature-sections#component-64ac58e032276db96bf343a8d4f332a8
  return (
    <Card>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            {Content.effectiveHeader}
          </p>
          {Content.effectiveText.split(/\n\n/g).map((description, i) => (
            <p
              key={description}
              className="mt-4 text-xl text-gray-500 lg:mx-auto"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          ))}
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-10 md:gap-y-10">
            {Content.effectivePractices.map(practice => (
              <div key={practice.name} className="flex flex-col md:flex-row">
                <div className="my-4 md:my-0">
                  <practice.icon
                    className="mx-auto h-10 w-10 text-secondary items-center justify-center"
                    aria-hidden="true"
                  />
                </div>
                <div>
                  <p className="text-center md:text-left md:ml-8 text-lg leading-6 font-medium text-gray-900">
                    {practice.name}
                  </p>
                  <p className="text-center md:text-left mt-2 md:ml-8 text-base text-gray-500">
                    {practice.description}
                  </p>
                </div>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </Card>
  )
}

function Comparison() {
  // Original Tailwind UI component: https://tailwindui.com/components/marketing/sections/pricing#component-466fb280c935eaf1bbc06080419b751f
  return (
    <Card>
      <div className="mb-8 lg:text-center lg:mb-12">
        <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          {Content.comparisonHeader}
        </p>
        <p className="mt-2 text-lg leading-8 font-extrabold tracking-tight text-gray-500">
          {Content.comaprisonText}
        </p>
      </div>
      {/* xs to md */}
      <div className="max-w-2xl mx-auto space-y-8 md:hidden">
        {Content.comparison.map(comparison => (
          <table key={comparison.practice} className="w-full">
            <caption className="bg-gray-50 py-2 px-4 text-sm font-medium text-gray-900 text-left">
              {comparison.practice}
            </caption>
            <p className="p-2 px-6 text-sm text-gray-500 text-left align-text-top">
              {comparison.application}
            </p>
          </table>
        ))}
      </div>

      {/* md+ */}
      <div className="hidden md:block">
        <div className="w-full">
          <div className="grid grid-cols-3">
            <p className="pb-4 px-6 text-xl leading-6 font-bold text-gray-900">
              {Content.comparisonNCTMSubheader}
            </p>
            <p className="pb-4 col-span-2 px-6 text-xl leading-6 font-bold text-gray-900">
              {Content.comparisonYupSubheader}
            </p>
          </div>
          <div className="border-t border-gray-100 divide-y divide-gray-200">
            {Content.comparison.map(comparison => (
              <div
                key={`detail-${comparison.application}`}
                className="grid grid-cols-3"
              >
                <p className="py-2 pl-6">{comparison.practice}</p>
                <p className="py-2 pl-6 col-span-2">{comparison.application}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Card>
  )
}

function AcademicsTeamSection() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/team-sections#component-0efa5ebc92e2aa72bc2332fcf5578869
  return (
    <div className="mx-auto pt-8 pb-12 px-4 max-w-7xl mt-12">
      <h2 className="text-3xl font-extrabold my-4 tracking-tight sm:text-4xl text-center">
        {Content.academicsTeamHeader}
      </h2>
      <p className="text-lg text-gray-500 text-center mb-8">
        {Content.academicsTeamText}
      </p>
      <ul className="space-y-12 md:grid md:grid-cols-3 md:gap-y-8 md:space-y-0">
        {Content.academicsTeam.map(person => (
          <li key={person.name} className="px-4 mx-auto">
            <ProfileCard>
              <Img
                src={person.profile}
                alt={person.name}
                className="shadow-lg rounded-lg w-full h-48 lg:h-36"
                cover={true}
              />
              <div className="text-lg leading-6 font-medium space-y-1">
                <div className="flex items-center">
                  <h3>{person.name}</h3>
                  <a
                    href={person.linkedIn}
                    target="_blank"
                    className="text-gray-400 hover:text-gray-500 ml-4"
                  >
                    <svg
                      className="w-5 h-5"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a>
                </div>
                <p className="text-secondary">{person.role}</p>
              </div>
              <div className="text-lg">
                <p className="text-gray-500">{person.bio}</p>
              </div>
            </ProfileCard>
          </li>
        ))}
      </ul>
    </div>
  )
}

function TutoringTeamSection() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/team-sections#component-0efa5ebc92e2aa72bc2332fcf5578869
  return (
    <div className="mx-auto pt-8 pb-12 px-4 max-w-7xl mt-12">
      <div className="space-y-12 md:grid md:grid-cols-3 md:space-y-0">
        <div className="space-y-5 sm:space-y-4 px-0 md:px-8 text-center md:text-left">
          <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
            {Content.tutoringTeamHeader}
          </h2>
          <p className="text-lg text-gray-500">{Content.tutoringTeamText}</p>
          <div className="my-8">
            <SecondaryButton
              href={Content.tutoringTeamLink}
              text={Content.tutoringTeamAction}
            />
          </div>
        </div>
        <div className="md:col-span-2">
          <ul className="space-y-12 md:grid md:grid-cols-2 md:space-y-0">
            {Content.tutoringTeam.map(person => (
              <li key={person.name} className="px-4 mx-auto">
                <ProfileCard>
                  <Img
                    src={person.profile}
                    alt={person.name}
                    className="shadow-lg rounded-lg w-full h-48 lg:h-36"
                    cover={true}
                  />
                  <div className="text-lg leading-6 font-medium space-y-1">
                    <h3>{person.name}</h3>
                    <p className="text-secondary">{person.role}</p>
                  </div>
                  <div className="text-lg">
                    <p className="text-gray-500">{person.bio}</p>
                  </div>
                </ProfileCard>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

const LearningFundamentals = () => {
  return (
    <Layout>
      <Seo
        title={Content.pageName}
        description={Content.header}
        route={Routes.learningFundamentals}
      />
      <PageHeader
        smallTitle={Content.subheader}
        mainTitle={Content.header}
        description={Content.description}
        video={Content.video}
        ctaLink={Content.headerCTALink}
        ctaText={Content.headerCTAText}
      />
      <Quotes />
      <ValuesList />
      <ValuesListLearnMore />
      <EffectiveTeachingSection />
      <Comparison />
      <AcademicsTeamSection />
      <TutoringTeamSection />
      <CTA title={Content.cta} actions={Content.ctaActions} />
    </Layout>
  )
}

export default LearningFundamentals
